
  /*------------------------------------
    Hurdles - Team slide
  ------------------------------------*/
  .hurdles {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
  }
  
  .hurdles-stakeholder {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 45px);
    left: calc(50% - 45px);
  }
  
  @media (min-width: 670px) {
    .hurdles-stakeholder {
      width: 120px;
      height: 120px;
      top: calc(50% - 60px);
      left: calc(50% - 60px);
    }
  }
  
  .hurdles-stakeholder-label {
    width: auto;
    display: block;
    color: currentColor;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translate(-50%, 100%) translateY(5px);
    transform: translate(-50%, 100%) translateY(5px);
    text-transform: uppercase;
    font: 600 19px Camphor;
    letter-spacing: 0.025em;
    padding: 3px 10px;
    opacity: 0;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
  }
  .hurdles-stakeholder--merchant {
    background-color: #c5f0fe;
    color: #4bb3e6;
    z-index: 2;
  }
  .hurdles-stakeholder--merchant svg {
    width: 100%;
    height: 100%;
  }
  .hurdles-stakeholder--merchant.moved .hurdles-stakeholder-label {
    opacity: 1;
    -webkit-transform: translate(-50%, 100%) translateY(15px);
    transform: translate(-50%, 100%) translateY(15px);
  }
  
  .hurdles-bubbles {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .hurdles-node {
    position: absolute;
    font-size: 14px;
    padding: 0 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 9999px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 30px;
  }
  
  @media (min-width: 880px) {
    .hurdles-node {
      font-size: 16px;
      height: 40px;
    }
  }
  
  .hurdles-node--blue {
    background-color: #c4f0ff;
    color: #217ab7;
  }
  .hurdles-node--pink {
    background-color: #ffe0f5;
    color: #9251ac;
  }
  .hurdles-node--yellow {
    background-color: #fdeebe;
    color: #c85d42;
  }
  .hurdles-node--green {
    background-color: #d6facf;
    color: #159570;
  }
  